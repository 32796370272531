import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { AutoFocusedForm, CheckboxField, Modal } from '../../../../../components';

import { MutableButton } from '../../../../../ui/MutableButton';
import { RedButton } from '../../../../../components/Buttons';
import { useSendReportSpecialProjectForm } from './useSendReportSpecialProjectForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 500,
      maxWidth: 1000,
    },
    content: {
      margin: '10px',
    },
    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export const SendReportModal = () => {
  const classes = useStyles();
  const { onSubmit, loading, initialValues, onCancel } = useSendReportSpecialProjectForm();
  return (
    <Modal open={true} onClose={onCancel}>
      <AutoFocusedForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Card className={classes.root}>
                <CardHeader title="Отправка отчёта" />
                <Divider />
                <CardContent>
                  <Typography variant="h5" component="h5">
                    Вы хотите отправить отчёт о закрытии спецпроекта?
                  </Typography>
                  <CheckboxField
                    name={'useFirstDocumentImageForNotification'}
                    label={'Использовать первое изображение отчёта в оповещении'}
                  />
                </CardContent>
              </Card>
              <CardActions className={classes.actions}>
                <MutableButton
                  loading={loading}
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  OK
                </MutableButton>
                <RedButton
                  color="primary"
                  size="medium"
                  type="button"
                  variant="contained"
                  onClick={onCancel}
                >
                  Отменить
                </RedButton>
              </CardActions>
            </form>
          );
        }}
      />
    </Modal>
  );
};
