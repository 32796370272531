export const toDateTime = (date: Date) => {
  const options = {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const newDate = new Date(date);
  return newDate.toLocaleString('ru', options);
};
