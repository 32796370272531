import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme, createStyles } from '@material-ui/core';
import { DataGrid } from 'components';
import { useQueryVariables, ColumnConfig, DataConnection } from 'hooks';
import { toDateTimeString } from 'helpers';
import { SpecialProjectsPropsFragment, useSpecialProjectsQuery } from 'schema/serverTypes';
import { Link } from 'react-router-dom';
import { DataGridProps } from 'components/DataGrid/types';

import { toast } from 'react-toastify';
import { SpecialProjectStatuses } from './SpecialProjectStatuses';
import { SpecialProjectSearch } from './SpecialProjectSearch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    link: {
      color: theme.palette.primary.main,
    },
    statusAlarm: {
      color: theme.palette.error.main,
    },
    statusSuccess: {
      color: theme.palette.success.main,
    },
    statusWarning: {
      color: theme.palette.warning.main,
    },
    dateOfDeath: {
      textAlign: 'center',
    },
  })
);

const wrapTableCellWithLink = (project: SpecialProjectsPropsFragment, cell: React.ReactNode) => (
  <Link to={`/special-projects/manage/${project.id}`}>{cell}</Link>
);

const getDataKey = (project: SpecialProjectsPropsFragment) => project.id;

export const SpecialProjectsGrid = () => {
  const classes = useStyles();

  const config = useMemo(() => {
    const columns: ColumnConfig<SpecialProjectsPropsFragment>[] = [
      {
        id: 'status',
        header: 'Статус',
        align: 'center',
        style: { width: '5%' },
        render: (project) => <SpecialProjectStatuses project={project} />,
      },
      {
        id: 'title',
        header: 'Название',
        style: { width: '15%' },
        render: (project: SpecialProjectsPropsFragment) => {
          return (
            <div className={classes.nameContainer}>
              {wrapTableCellWithLink(
                project,
                <Typography variant="body1" className={classes.link}>
                  {project.title}
                </Typography>
              )}
            </div>
          );
        },
      },
      {
        id: 'city',
        header: 'Город',
        style: { width: '10%' },
        render: (project: SpecialProjectsPropsFragment) => project.city,
      },
      {
        id: 'start',
        header: 'Дата начала',
        style: { width: '10%' },
        render: (project: SpecialProjectsPropsFragment) => {
          const date = new Date(project.start as string);
          return <div>{toDateTimeString(date)}</div>;
        },
      },
      {
        id: 'end',
        header: 'Дата завершения',
        style: { width: '10%' },
        render: (project) => {
          const date = new Date(project?.end as string);
          return <div>{project.end ? toDateTimeString(date) : 'Не задана'}</div>;
        },
      },
    ];
    return {
      columns,
      rowsPerPage: 50,
    };
  }, [classes]);

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
    onIncludeClosedOrCancelled,
    onSearchCity,
  } = useQueryVariables<SpecialProjectsPropsFragment>(config);

  const { data, loading, error } = useSpecialProjectsQuery({
    variables: {
      ...variables,
      includeClosedOrCancelled: variables.includeClosedOrCancelled as boolean,
    },
  });

  if (error) {
    toast.error('Ошибка загрузки спецпроектов');
    return null;
  }

  const entities = data?.specialProjects as DataConnection<SpecialProjectsPropsFragment>;
  const {
    data: specialProjects,
    count,
    startCursor,
    endCursor,
    page,
    rowsPerPage,
  } = extractPagedData(entities);

  const gridProps: DataGridProps<SpecialProjectsPropsFragment> = {
    data: specialProjects,
    onSort,
    onPage,
    onSearch,
    columns,
    getDataKey,
    onIncludeClosedOrCancelled,
    onSearchCity,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    searchComponent: SpecialProjectSearch,
    rowsPerPage,
    sortDirection,
    sortBy,
    loading,
  };

  return (
    <>
      <DataGrid<SpecialProjectsPropsFragment> {...gridProps} />
    </>
  );
};
